import React from "react";
import { useFormContext } from "react-hook-form";

const InputText = ({ text, isFocused = false, ...props }) => {
  const { register } = useFormContext();

  return (
    <div className="flex flex-col gap-y-1">
      <label htmlFor={props.name} className="block flex-grow text-sm font-content text-embie-blue dark:text-embie-grey">
        {text}
      </label>
      <input
        autoFocus={isFocused}
        type="text"
        autoComplete="on"
        id={props.name}
        className="block w-full py-1 px-2 shadow-sm rounded bg-embie-grey focus:outline-none focus:ring-1 focus:ring-embie-lightblue text-embie-blue font-content border border-embie-lightblue dark:focus:border-embie-lightblue dark:border-embie-blue"
        {...register(props.name, {
          required: true,
        })}
        {...props}
      />
    </div>
  );
};

export default InputText;
