import React, { Fragment, useContext, useEffect, useState } from "react";
import "../styles/tailwind.css";
import FormInvitation from "./form";
import { ThemeContext, ThemeProvider } from "../components/themeContext";
import { useForm } from "react-hook-form";
import { ChakraProvider, Collapse, Fade, useDisclosure } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import favicon from "../images/favicon.png";
import embieLogoWithText from "../images/embie-with-text.png";
import { Modal } from "../components/modals";

const SunIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4 text-embie-grey hidden dark:block"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
    />
  </svg>
);

const MoonIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4 text-embie-blue dark:hidden"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
    />
  </svg>
);

const ThemeButton = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  return (
    <button
      onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
      className="fixed top-6 right-6 lg:right-10 p-2 rounded-md dark:bg-embie-blue bg-embie-grey transition duration-150"
    >
      <SunIcon />
      <MoonIcon />
    </button>
  );
};

const Layout = ({ children }) => {
  return (
    <ThemeProvider>
      <div className="flex flex-col min-h-screen bg-embie-grey dark:bg-embie-blue transition duration-150 overflow-hidden">{children}</div>
    </ThemeProvider>
  );
};

const Header = () => {
  return (
    <header className="text-center pt-10 sm:pt-16 px-5">
      <div className="flex flex-col justify-center items-center gap-y-6">
        <EmbieLogo />
        <div className="flex justify-center items-center">
          <h1 className="text-8xl lg:text-9xl font-bold text-embie-blue dark:text-embie-red transition duration-150">2</h1>
          <div className="flex flex-col items-start">
            <h3 className="text-2xl lg:text-3xluppercase tracking-wide dark:text-embie-grey text-embie-lightblue transition duration-150 -mb-5 mt-6 lg:mt-8">
              Let's celebrate
            </h3>
            <h2 className="text-6xl lg:text-7xl tracking-wide font-bold">
              <span className="text-embie-blue dark:text-embie-red transition duration-150">years</span>
            </h2>
          </div>
        </div>
      </div>
    </header>
  );
};

const Newsletter = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });
  const onSubmit = () => {
    onOpen();
  };
  return (
    <form className="space-y-4 sm:max-w-sm flex flex-col items-start" onSubmit={handleSubmit(onSubmit)}>
      <p className="mt-10 text-left dark:text-embie-grey text-embie-blue mr-12">
        Restez à jour avec notre newsletter mensuelle et ne manquez pas de découvrir les produits que nous avons construit!
      </p>
      <div className="relative rounded-md shadow-sm px-3 active:outline-none w-full py-2 ring-1 focus-within:ring-2 dark:ring-embie-grey ring-embie-blue">
        <input
          type="text"
          name="email"
          id="email"
          className="block bg-transparent dark:bg-[#3185D9] appearance-none w-full focus:outline-none placeholder-transparent focus:placeholder-embie-blue focus:placeholder-opacity-30 duration-300 dark:text-embie-grey text-embie-blue font-content"
          placeholder="jesuis@embie.be"
          {...register("newsletter", {
            minLength: 3,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Entered value does not match email format",
            },
          })}
        />
        <label
          htmlFor="email"
          className="font-content dark:text-embie-grey text-embie-blue transition ease-out duration-300 absolute py-2 left-3 top-0 font-medium"
        >
          Email
        </label>
      </div>
      <Fade in={watch("newsletter")?.length > 2 && isValid} unmountOnExit>
        <button
          type="submit"
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-embie-yellow dark:text-embie-grey bg-embie-blue dark:bg-opacity-70 hover:bg-embie-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-embie-yellow dark:focus:ring-offset-embie-lightblue focus:ring-embie-blue"
        >
          S'inscrire
        </button>
      </Fade>
      <Collapse in={isOpen} animateOpacity>
        <p className="text-embie-blue dark:text-embie-grey text-left">
          Nous n'avons pas encore de newsletter mais vous pouvez nous suivre{" "}
          <a href="https://www.facebook.com/embie.be" target="_blank" className="underline">
            ici
          </a>
          &nbsp;ou&nbsp;
          <a href="https://www.instagram.com/embie.be" target="_blank" className="underline">
            là
          </a>
        </p>
      </Collapse>
    </form>
  );
};

const SocialMediaIcon = ({ href, children }) => {
  return (
    <a
      href={href}
      target="_blank"
      className="cursor-pointer group border dark:border-embie-grey border-embie-blue rounded-full p-2 dark:hover:border-embie-blue hover:border-embie-grey"
    >
      {children}
    </a>
  );
};

const FollowUs = () => {
  return (
    <div className="mt-16 text-left font-content dark:text-embie-grey text-embie-blue space-y-4">
      <p>Suivez-nous sur nos réseaux sociaux</p>
      <div className="flex gap-x-4">
        <SocialMediaIcon href="https://www.facebook.com/embie.be">
          <svg
            className="w-6 h-6 fill-current dark:text-embie-grey text-embie-blue dark:group-hover:text-embie-blue group-hover:text-embie-grey"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
            ></path>
          </svg>
        </SocialMediaIcon>
        <SocialMediaIcon href="https://www.instagram.com/embie.be">
          <svg
            className="w-6 h-6 fill-current dark:text-embie-grey text-embie-blue dark:group-hover:text-embie-blue group-hover:text-embie-grey"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
            ></path>
          </svg>
        </SocialMediaIcon>
      </div>
    </div>
  );
};

const FooterWaves = () => {
  return (
    <div className="absolute top-0 left-0 w-full overflow-hidden leading-none ">
      <svg
        className="relative block w-[101%] h-[50px] sm:h-[100px]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
          opacity=".25"
          className="fill-current text-embie-grey dark:text-embie-blue transition duration-150"
        ></path>
        <path
          d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
          opacity=".5"
          className="fill-current text-embie-grey dark:text-embie-blue transition duration-150"
        ></path>
        <path
          d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
          className="fill-current text-embie-grey dark:text-embie-blue transition duration-150"
        ></path>
      </svg>
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="text-center mt-10 sm:mt-16 relative overflow-hidden">
      <FooterWaves />
      <div className="dark:bg-[#3185D9] bg-embie-yellow pt-[120px] lg:pt-[210px] pb-20 lg:pb-28 sm:pt-[150px]">
        <div className="relative w-10/12 mx-auto lg:w-9/12 xl:max-w-3xl">
          <HexagonEmbie />
          <div className="relative">
            <Newsletter />
            <FollowUs />
          </div>
          <div className="relative flex justify-center mt-16 items-center gap-x-4">
            <img src={embieLogoWithText} alt="embie logo with text" className="h-16" />
          </div>
        </div>
      </div>
    </footer>
  );
};

const HexagonEmbie = () => (
  <svg
    version="1.1"
    className="absolute bottom-0 -right-8 w-48 md:w-64 stroke-current text-embie-lightblue dark:text-embie-yellow transform -rotate-45"
    viewBox="0 0 171.2 188.3"
  >
    <path
      className="st0"
      d="M85.6,188.3c-5.6,0-11-1.5-15.9-4.2L15.9,153C6.1,147.3,0,136.8,0,125.5V63.3C0,52,6.1,41.5,15.9,35.8L69.7,4.7
	c9.8-5.7,21.9-5.7,31.8,0l53.8,31.1c9.8,5.7,15.9,16.2,15.9,27.5v62.2c0,11.3-6.1,21.8-15.9,27.5l-53.8,31.1
	C96.6,186.9,91.2,188.3,85.6,188.3z M85.6,1.5c-5.4,0-10.7,1.4-15.4,4.1L16.4,36.7C6.9,42.2,1,52.3,1,63.3v62.2
	c0,11,5.9,21.1,15.4,26.6l53.8,31.1c9.5,5.5,21.2,5.5,30.8,0l53.8-31.1c9.5-5.5,15.4-15.7,15.4-26.6V63.3c0-11-5.9-21.1-15.4-26.6
	L101,5.6C96.3,2.9,91,1.5,85.6,1.5z"
    />
    <path
      className="st1"
      d="M-110.4,188.3c-5.6,0-11-1.5-15.9-4.2l-53.8-31.1c-9.8-5.7-15.9-16.2-15.9-27.5V63.3c0-11.3,6.1-21.8,15.9-27.5
	l53.8-31.1c9.8-5.7,21.9-5.7,31.8,0l53.8,31.1c9.8,5.7,15.9,16.2,15.9,27.5v62.2c0,11.3-6.1,21.8-15.9,27.5l-53.8,31.1
	C-99.4,186.9-104.8,188.3-110.4,188.3z M-110.4,1.5c-5.4,0-10.7,1.4-15.4,4.1l-53.8,31.1c-9.5,5.5-15.4,15.7-15.4,26.6v62.2
	c0,11,5.9,21.1,15.4,26.6l53.8,31.1c9.5,5.5,21.2,5.5,30.8,0l53.8-31.1c9.5-5.5,15.4-15.7,15.4-26.6V63.3c0-11-5.9-21.1-15.4-26.6
	L-95,5.6C-99.7,2.9-105,1.5-110.4,1.5z"
    />
    <path
      className="st2"
      d="M-306.4,188.3c-5.6,0-11-1.5-15.9-4.2l-53.8-31.1c-9.8-5.7-15.9-16.2-15.9-27.5V63.3c0-11.3,6.1-21.8,15.9-27.5
	l53.8-31.1c9.8-5.7,21.9-5.7,31.8,0l53.8,31.1c9.8,5.7,15.9,16.2,15.9,27.5v62.2c0,11.3-6.1,21.8-15.9,27.5l-53.8,31.1
	C-295.4,186.9-300.8,188.3-306.4,188.3z M-306.4,1.5c-5.4,0-10.7,1.4-15.4,4.1l-53.8,31.1c-9.5,5.5-15.4,15.7-15.4,26.6v62.2
	c0,11,5.9,21.1,15.4,26.6l53.8,31.1c9.5,5.5,21.2,5.5,30.8,0l53.8-31.1c9.5-5.5,15.4-15.7,15.4-26.6V63.3c0-11-5.9-21.1-15.4-26.6
	L-291,5.6C-295.7,2.9-301,1.5-306.4,1.5z"
    />
    <path
      className="st3"
      d="M-502.4,188.3c-5.6,0-11-1.5-15.9-4.2l-53.8-31.1c-9.8-5.7-15.9-16.2-15.9-27.5V63.3c0-11.3,6.1-21.8,15.9-27.5
	l53.8-31.1c9.8-5.7,21.9-5.7,31.8,0l53.8,31.1c9.8,5.7,15.9,16.2,15.9,27.5v62.2c0,11.3-6.1,21.8-15.9,27.5l-53.8,31.1
	C-491.4,186.9-496.8,188.3-502.4,188.3z M-502.4,1.5c-5.4,0-10.7,1.4-15.4,4.1l-53.8,31.1c-9.5,5.5-15.4,15.7-15.4,26.6v62.2
	c0,11,5.9,21.1,15.4,26.6l53.8,31.1c9.5,5.5,21.2,5.5,30.8,0l53.8-31.1c9.5-5.5,15.4-15.7,15.4-26.6V63.3c0-11-5.9-21.1-15.4-26.6
	L-487,5.6C-491.7,2.9-497,1.5-502.4,1.5z"
    />
    <path
      className="st4"
      d="M-698.4,188.3c-5.6,0-11-1.5-15.9-4.2l-53.8-31.1c-9.8-5.7-15.9-16.2-15.9-27.5V63.3c0-11.3,6.1-21.8,15.9-27.5
	l53.8-31.1c9.8-5.7,21.9-5.7,31.8,0l53.8,31.1c9.8,5.7,15.9,16.2,15.9,27.5v62.2c0,11.3-6.1,21.8-15.9,27.5l-53.8,31.1
	C-687.4,186.9-692.8,188.3-698.4,188.3z M-698.4,1.5c-5.4,0-10.7,1.4-15.4,4.1l-53.8,31.1c-9.5,5.5-15.4,15.7-15.4,26.6v62.2
	c0,11,5.9,21.1,15.4,26.6l53.8,31.1c9.5,5.5,21.2,5.5,30.8,0l53.8-31.1c9.5-5.5,15.4-15.7,15.4-26.6V63.3c0-11-5.9-21.1-15.4-26.6
	L-683,5.6C-687.7,2.9-693,1.5-698.4,1.5z"
    />
  </svg>
);

const pathVariants = {
  hidden: { opacity: 0, pathLength: 0 },
  visible: i => {
    const delay = i * 0.4;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 1 },
      },
    };
  },
};

const EmbieLogo = () => {
  return (
    <div className="w-12 z-10">
      <motion.svg className="w-12" viewBox="0 0 122.43 134.93" initial="hidden" animate="visible">
        <polyline
          className="fill-current text-transparent"
          points="122.41 34.05 122.41 92.52 86.68 125.2 70.77 134.38 62.37 132.53 51.62 134.38 3.99 106.85 0 53.42 0 33.86 57.09 0.95 9.69 28.27 0 33.86"
        />
        <motion.path
          variants={pathVariants}
          custom={2}
          className="fill-current text-embie-lightblue"
          d="M119.18,102.71a22.77,22.77,0,0,0,3.23-10.19C122.74,85,117,82.29,110.6,86L70.81,109a8.81,8.81,0,0,0-4,6.89v8.3c0,.12,0,.25,0,.37v7.59c0,2.53,1.79,3.55,4,2.27L111.36,111A22.49,22.49,0,0,0,119.18,102.71Z"
        />
        <motion.path
          variants={pathVariants}
          custom={0.5}
          className="fill-current text-embie-lightblue"
          d="M121.07,60.11c4.23-11.27-2.11-17.37-9.71-13L70.8,70.55a8.79,8.79,0,0,0-4,6.88V93.67c0,2.53,1.79,3.55,4,2.28l39.54-22.83A26.2,26.2,0,0,0,121.07,60.11Z"
        />
        <motion.path
          variants={pathVariants}
          custom={0.5}
          className="fill-current text-embie-yellow"
          d="M52.37,74.68a23,23,0,0,0-7.26-7.89c-6.39-4.06-11.63-.51-11.63,6.84v45.66a8.77,8.77,0,0,0,4,6.88l7.23,4.18c.11.06.2.15.31.21l6.61,3.82c2.21,1.27,4,.26,4-2.27V85.53A22.31,22.31,0,0,0,52.37,74.68Z"
        />
        <motion.path
          variants={pathVariants}
          custom={2}
          className="fill-current text-embie-yellow"
          d="M16.2,51.63C8.5,42.34,0,44.7,0,53.42V100a8.83,8.83,0,0,0,4,6.89L18.14,115c2.21,1.27,4,.25,4-2.28V67.37A26.07,26.07,0,0,0,16.2,51.63Z"
        />
        <motion.path
          variants={pathVariants}
          custom={0.5}
          className="fill-current text-embie-red"
          d="M71.58,43.18l-39.82-23a8.81,8.81,0,0,0-8,0L16.6,24.32c-.1.06-.23.1-.34.16L9.69,28.27c-2.2,1.27-2.19,3.33,0,4.6L50.33,56.33A22.69,22.69,0,0,0,61.38,59a22.87,22.87,0,0,0,10.45-2.31C78.51,53.2,78,46.87,71.58,43.18Z"
        />
        <motion.path
          variants={pathVariants}
          custom={2}
          className="fill-current text-embie-red"
          d="M105.64,24.4,65.05,1a8.79,8.79,0,0,0-8,0L43,9.07c-2.19,1.27-2.18,3.32,0,4.6L82.61,36.52A26.25,26.25,0,0,0,99.25,39.3C111.14,37.32,113.25,28.78,105.64,24.4Z"
        />
      </motion.svg>
    </div>
  );
};

const EmbieLogoInfinity = () => {
  return (
    <div className="w-12 z-10">
      <motion.svg className="w-12" viewBox="0 0 122.43 134.93" initial="hidden" animate="visible">
        <polyline
          className="fill-current text-transparent"
          points="122.41 34.05 122.41 92.52 86.68 125.2 70.77 134.38 62.37 132.53 51.62 134.38 3.99 106.85 0 53.42 0 33.86 57.09 0.95 9.69 28.27 0 33.86"
        />
        <motion.path
          initial={{ opacity: 0, pathLength: 0 }}
          animate={{
            pathLength: 1,
            opacity: [0, 1, 1],
          }}
          transition={{ repeat: Infinity, repeatType: "reverse", duration: 1, delay: 1 }}
          className="fill-current text-embie-lightblue"
          d="M119.18,102.71a22.77,22.77,0,0,0,3.23-10.19C122.74,85,117,82.29,110.6,86L70.81,109a8.81,8.81,0,0,0-4,6.89v8.3c0,.12,0,.25,0,.37v7.59c0,2.53,1.79,3.55,4,2.27L111.36,111A22.49,22.49,0,0,0,119.18,102.71Z"
        />
        <motion.path
          initial={{ opacity: 0, pathLength: 0 }}
          animate={{
            pathLength: 1,
            opacity: 1,
          }}
          transition={{ repeat: Infinity, repeatType: "reverse", duration: 1, delay: 0.5 }}
          className="fill-current text-embie-lightblue"
          d="M121.07,60.11c4.23-11.27-2.11-17.37-9.71-13L70.8,70.55a8.79,8.79,0,0,0-4,6.88V93.67c0,2.53,1.79,3.55,4,2.28l39.54-22.83A26.2,26.2,0,0,0,121.07,60.11Z"
        />
        <motion.path
          initial={{ opacity: 0, pathLength: 0 }}
          animate={{
            pathLength: 1,
            opacity: 1,
          }}
          transition={{ repeat: Infinity, repeatType: "reverse", duration: 1, delay: 0.5 }}
          className="fill-current text-embie-yellow"
          d="M52.37,74.68a23,23,0,0,0-7.26-7.89c-6.39-4.06-11.63-.51-11.63,6.84v45.66a8.77,8.77,0,0,0,4,6.88l7.23,4.18c.11.06.2.15.31.21l6.61,3.82c2.21,1.27,4,.26,4-2.27V85.53A22.31,22.31,0,0,0,52.37,74.68Z"
        />
        <motion.path
          initial={{ opacity: 0, pathLength: 0 }}
          animate={{
            pathLength: 1,
            opacity: [0, 1, 1],
          }}
          transition={{ repeat: Infinity, repeatType: "reverse", duration: 1, delay: 1 }}
          className="fill-current text-embie-yellow"
          d="M16.2,51.63C8.5,42.34,0,44.7,0,53.42V100a8.83,8.83,0,0,0,4,6.89L18.14,115c2.21,1.27,4,.25,4-2.28V67.37A26.07,26.07,0,0,0,16.2,51.63Z"
        />
        <motion.path
          initial={{ opacity: 0, pathLength: 0 }}
          animate={{
            pathLength: 1,
            opacity: 1,
          }}
          transition={{ repeat: Infinity, repeatType: "reverse", duration: 1, delay: 0.5 }}
          className="fill-current text-embie-red"
          d="M71.58,43.18l-39.82-23a8.81,8.81,0,0,0-8,0L16.6,24.32c-.1.06-.23.1-.34.16L9.69,28.27c-2.2,1.27-2.19,3.33,0,4.6L50.33,56.33A22.69,22.69,0,0,0,61.38,59a22.87,22.87,0,0,0,10.45-2.31C78.51,53.2,78,46.87,71.58,43.18Z"
        />
        <motion.path
          initial={{ opacity: 0, pathLength: 0 }}
          animate={{
            pathLength: 1,
            opacity: [0, 1, 1],
          }}
          transition={{ repeat: Infinity, repeatType: "reverse", duration: 1, delay: 1 }}
          className="fill-current text-embie-red"
          d="M105.64,24.4,65.05,1a8.79,8.79,0,0,0-8,0L43,9.07c-2.19,1.27-2.18,3.32,0,4.6L82.61,36.52A26.25,26.25,0,0,0,99.25,39.3C111.14,37.32,113.25,28.78,105.64,24.4Z"
        />
      </motion.svg>
    </div>
  );
};

const Page = () => {
  const { theme } = useContext(ThemeContext);

  const [isLoading, setIsLoading] = useState(theme ? false : true);

  useEffect(() => {
    setIsLoading(false);
  }, [theme]);

  return (
    <Fragment>
      <Helmet>
        <title>Embie - 2 Years</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <ChakraProvider>
        <Modal />
        <Layout>
          {isLoading ? (
            <div className="flex-grow flex justify-center items-center">
              <EmbieLogoInfinity />
            </div>
          ) : (
            <>
              <Header />
              <main className="mt-10 sm:mt-16 max-w-lg mx-auto flex-grow px-8 w-full">
                <FormInvitation />
              </main>
              <Footer />
              <ThemeButton />
            </>
          )}
        </Layout>
      </ChakraProvider>
    </Fragment>
  );
};

export default Page;
