import React, { useState } from "react";
import { useForm, FormProvider, useFormContext, useController } from "react-hook-form";
import InputTextarea from "../components/inputTextarea";
import InputText from "../components/inputText";
import { CheckCircleIcon, CheckIcon } from "@heroicons/react/outline";
import { RadioGroup } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { SlideFade } from "@chakra-ui/react";

var Airtable = require("airtable");
var base = new Airtable({ apiKey: process.env.GATSBY_API_KEY }).base(process.env.GATSBY_BASE);

const availabilityAnswers = [
  { value: "Oui", title: "Je viendrai avec plaisir fêter les 2 ans d'Embie" },
  { value: "Non", title: "Je ne pourrai malheureusement pas assister à l'évènement." },
];

const maxStep = 3;

const Availability = () => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, name, value, ref },
  } = useController({ name: "availability", control });

  return (
    <RadioGroup value={value} onChange={onChange} onBlur={onBlur}>
      <RadioGroup.Label className="font-content text-embie-blue dark:text-embie-grey">
        Nous ferez vous l'honneur d'être présent ?
      </RadioGroup.Label>

      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
        {availabilityAnswers.map(answer => (
          <RadioGroup.Option
            key={answer.value}
            value={answer.value}
            className={({ checked, active }) =>
              `relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none ${
                checked ? "border-transparent" : "border-gray-300"
              } ${active ? "ring-2 ring-embie-lightblue" : ""}`
            }
          >
            {({ checked, active }) => (
              <>
                <div className="flex-1 flex">
                  <div className="flex flex-col">
                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                      {answer.value}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                      {answer.title}
                    </RadioGroup.Description>
                  </div>
                </div>
                <CheckCircleIcon className={`h-5 w-5 text-embie-lightblue ${!checked ? "invisible" : ""}`} aria-hidden="true" />
                <div
                  className={`absolute -inset-px rounded-lg pointer-events-none ${
                    checked ? "border-embie-lightblue" : "border-transparent"
                  } ${active ? "border" : "border-2"}`}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

const ContactDetails = () => {
  return (
    <div className="flex flex-col gap-y-6">
      <InputText name="lastName" text="Nom" />
      <InputText name="firstName" text="Prénom" />
    </div>
  );
};

const Comment = () => {
  return (
    <InputTextarea
      name="comment"
      label="Auriez-vous un commentaire à nous faire parvenir ?"
      placeholder="Arrivée tardive, intolérances alimentaires, ..."
    />
  );
};

const STEPS = [
  {
    component: <ContactDetails />,
    isComplete: values => values?.lastName && values?.firstName,
  },
  {
    component: <Availability />,
    isComplete: values => values?.availability,
  },
  {
    component: <Comment />,
    isComplete: values => values?.comment,
  },
];

const Steps = ({ curStep }) => (
  <AnimatePresence>
    <motion.div key={curStep} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0, display: "none" }}>
      {STEPS[curStep].component}
    </motion.div>
  </AnimatePresence>
);

const StepsCounter = ({ curStep }) => {
  const { watch } = useFormContext();

  const values = watch();
  return (
    <div aria-label="Progress" className="mx-auto">
      <ol role="list" className="flex items-center">
        {STEPS.map((step, stepIdx) => (
          <li key={stepIdx} className={`relative ${stepIdx !== STEPS.length - 1 ? "pr-8 sm:pr-20" : ""}`}>
            {step.isComplete(values) ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-embie-lightblue" />
                </div>
                <div className="relative w-6 h-6 flex items-center justify-center bg-embie-lightblue rounded-full hover:bg-indigo-900">
                  <CheckIcon className="w-3 h-3 text-embie-grey" aria-hidden="true" />
                </div>
              </>
            ) : stepIdx === curStep ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <div
                  className="relative w-6 h-6 flex items-center justify-center bg-white border-2 border-embie-lightblue rounded-full"
                  aria-current="step"
                >
                  <span className="h-2.5 w-2.5 bg-embie-lightblue rounded-full" aria-hidden="true" />
                </div>
              </>
            ) : (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <div className="group relative w-6 h-6 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400">
                  <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" aria-hidden="true" />
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};

const CircleEmbie = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 178.5 175.7"
    className="absolute -bottom-10 -right-48 lg:-right-72 stroke-current text-embie-lightblue/80 dark:text-embie-red w-52"
  >
    <path
      d="M89.1,1.9c47.5,0,86,38.5,86,86s-38.5,86-86,86s-86-38.5-86-86C3.2,40.4,41.6,1.9,89.1,1.9 M89.1,0.9
	c-48,0-87,39-87,87s39,87,87,87s87-39,87-87l0,0C176.1,39.8,137.2,0.9,89.1,0.9z"
    />
    <path
      d="M-109.2,1.9c47.5,0,86,38.5,86,86s-38.5,86-86,86s-86-38.5-86-86C-195.1,40.4-156.7,1.9-109.2,1.9 M-109.2,0.9
	c-48,0-87,39-87,87s39,87,87,87s87-39,87-87l0,0C-22.2,39.8-61.2,0.9-109.2,0.9z"
    />
    <path
      d="M-307.5,1.9c47.5,0,86,38.5,86,86s-38.5,86-86,86s-86-38.5-86-86C-393.5,40.4-355,1.9-307.5,1.9 M-307.5,0.9
	c-48,0-87,39-87,87s39,87,87,87s87-39,87-87l0,0C-220.5,39.8-259.5,0.9-307.5,0.9z"
    />
    <path
      d="M-505.8,1.9c47.5,0,86,38.5,86,86s-38.5,86-86,86s-86-38.5-86-86C-591.8,40.4-553.3,1.9-505.8,1.9 M-505.8,0.9
	c-48,0-87,39-87,87s39,87,87,87s87-39,87-87l0,0C-418.8,39.8-457.8,0.9-505.8,0.9z"
    />
    <path
      d="M-704.2,1.9c47.5,0,86,38.5,86,86s-38.5,86-86,86c-47.5,0-86-38.5-86-86C-790.1,40.4-751.6,1.9-704.2,1.9
	 M-704.2,0.9c-48,0-87,39-87,87s39,87,87,87s87-39,87-87l0,0C-617.2,39.8-656.1,0.9-704.2,0.9z"
    />
  </svg>
);

const TriangleEmbie = () => (
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    className=" absolute -top-10 -left-20 lg:-left-52 transform rotate-45 stroke-current text-embie-purple dark:text-embie-yellow w-36"
    viewBox="0 0 178.1 159"
  >
    <path
      d="M160.1,159H18c-9.9,0-18-8.1-18-18c0-3.2,0.8-6.3,2.4-9L73.5,9l0.4,0.2L73.5,9c5-8.6,16-11.5,24.6-6.5
	c2.7,1.6,5,3.8,6.5,6.5l71.1,123.1c5,8.6,2,19.6-6.6,24.6C166.4,158.3,163.3,159.1,160.1,159L160.1,159z M89.1,1
	C83,1,77.4,4.2,74.4,9.5L3.3,132.6c-4.7,8.2-1.8,18.5,6.3,23.2c2.5,1.5,5.4,2.2,8.4,2.2h142.1c9.4,0,17-7.7,17-17
	c0-3-0.8-5.9-2.3-8.4l-71-123.1C100.8,4.2,95.1,1,89.1,1z"
    />
    <path
      d="M312.5-23.8H170.4c-9.9,0-18-8.1-18-18c0-3.2,0.8-6.3,2.4-9l71.1-123l0.4,0.2l-0.4-0.2c5-8.6,16-11.5,24.6-6.5
	c2.7,1.6,5,3.8,6.5,6.5l71.1,123.1c5,8.6,2,19.6-6.6,24.6C318.8-24.5,315.7-23.7,312.5-23.8L312.5-23.8z M241.4-181.8
	c-6.1,0-11.7,3.2-14.7,8.5l-71,123.1C151-42,153.9-31.7,162-27c2.5,1.5,5.4,2.2,8.4,2.2h142.1c9.4,0,17-7.7,17-17
	c0-3-0.8-5.9-2.3-8.4l-71.1-123.1C253.1-178.6,247.5-181.8,241.4-181.8z"
    />
    <path
      d="M494.8-131.4H352.6c-9.9,0-18-8.1-18-18c0-3.2,0.8-6.3,2.4-9l71.1-123l0.4,0.2l-0.4-0.2c5-8.6,16-11.5,24.6-6.5
	c2.7,1.6,5,3.8,6.5,6.5l71.1,123.1c5,8.6,2,19.6-6.6,24.6C501-132.1,497.9-131.3,494.8-131.4L494.8-131.4z M423.7-289.4
	c-6.1,0-11.7,3.2-14.7,8.5l-71.1,123.1c-4.7,8.2-1.8,18.5,6.3,23.2c2.5,1.5,5.4,2.2,8.4,2.2h142.1c9.4,0,17-7.7,17-17
	c0-3-0.8-5.9-2.3-8.4l-71.1-123.1C435.4-286.2,429.8-289.4,423.7-289.4z"
    />
    <path
      d="M95.8-131.4H-46.3c-9.9,0-18-8.1-18-18c0-3.2,0.8-6.3,2.4-9l71.1-123l0.4,0.2l-0.4-0.2c5-8.6,16-11.5,24.6-6.5
	c2.7,1.6,5,3.8,6.5,6.5l71.1,123.1c5,8.6,2,19.6-6.6,24.6C102.1-132.2,99-131.3,95.8-131.4L95.8-131.4z M24.8-289.4
	c-6.1,0-11.7,3.2-14.7,8.5L-61-157.8c-4.7,8.2-1.8,18.5,6.3,23.2c2.5,1.5,5.4,2.2,8.4,2.2H95.8c9.4,0,17-7.7,17-17
	c0-3-0.8-5.9-2.3-8.4l-71.1-123C36.5-286.2,30.8-289.5,24.8-289.4z"
    />
    <path
      d="M504.2,173.4H362c-9.9,0-18-8.1-18-18c0-3.2,0.8-6.3,2.4-9l71.1-123l0.4,0.2l-0.4-0.2c5-8.6,16-11.5,24.6-6.5
	c2.7,1.6,5,3.8,6.5,6.5l71.1,123.1c5,8.6,2,19.6-6.6,24.6C510.4,172.7,507.3,173.5,504.2,173.4L504.2,173.4z M433.1,15.4
	c-6.1,0-11.7,3.2-14.7,8.5L347.3,147c-4.7,8.2-1.8,18.5,6.3,23.2c2.5,1.5,5.4,2.2,8.4,2.2h142.1c9.4,0,17-7.7,17-17
	c0-3-0.8-5.9-2.3-8.4l-71-123.1C444.8,18.6,439.2,15.4,433.1,15.4z"
    />
  </svg>
);

const FormInvitation = () => {
  const [done, setDone] = useState(false);
  const [curStep, setCurStep] = useState(0);
  const methods = useForm();
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const values = methods.watch();

  const onSubmit = (data, e) => {
    e.preventDefault();

    const availability = data.availability === "Oui" ? "Présent(e)" : "Absent(e)";
    base("Présences").create(
      [
        {
          fields: {
            Nom: data.firstName + " " + data.lastName,
            Réponse: availability,
            Commentaire: data.comment,
          },
        },
      ],
      function (err, records) {
        if (err) {
          console.error(err);
          return;
        } else setDone(true);
      }
    );
  };

  const handleSteps = e => {
    e.preventDefault();
    if (curStep + 1 < STEPS.length) setCurStep(prevState => prevState + 1);
  };

  return (
    <FormProvider {...methods}>
      <SlideFade in={done} offsetY="40px" unmountOnExit>
        <div className="relative text-center py-32 sm:py-36">
          <TriangleEmbie />
          <CircleEmbie />
          <p className="relative text-embie-blue dark:text-embie-grey transition duration-150">
            {values.availability === "Oui" ? (
              <>
                <span className="uppercase">Génial</span>, on a hâte de te voir!
              </>
            ) : (
              <>
                Oh non, c'est dommage.
                <br />
                On se voit pour fêter les 3 ans d'Embie alors!
              </>
            )}
          </p>
        </div>
      </SlideFade>
      {!done && (
        <form className="flex flex-col gap-y-10 w-full" onSubmit={handleSubmit(onSubmit)}>
          {curStep === 0 && (
            <p className="text-lg leading-6 text-embie-blue dark:text-embie-grey max-w-sm">
              Merci de remplir ce petit questionnaire afin de confirmer votre venue.
            </p>
          )}
          <StepsCounter curStep={curStep} />
          <Steps curStep={curStep} />
          <div className="flex justify-end gap-x-4">
            {curStep > 0 && curStep <= maxStep && (
              <button
                type="button"
                onClick={() => setCurStep(prevState => prevState - 1)}
                className="ml-3 inline-flex my-auto justify-center text-sm font-medium rounded-md text-embie-lightblue focus:outline-none"
              >
                Retour
              </button>
            )}

            <button
              type={curStep + 1 === STEPS.length ? "submit" : "button"}
              disabled={curStep + 1 !== STEPS.length && !STEPS[curStep].isComplete(values)}
              onClick={e => curStep + 1 !== STEPS.length && handleSteps(e)}
              className={`ml-3 inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-md text-embie-blue focus:outline-none focus:ring-1 focus:ring-offset-2 dark:focus:ring-offset-embie-blue focus:ring-offset-embie-grey focus:ring-embie-yellow hover:animate-submit-pulse ${
                curStep + 1 !== STEPS.length && !STEPS[curStep].isComplete(values)
                  ? "cursor-not-allowed dark:bg-embie-red/50 bg-embie-yellow/50"
                  : "dark:bg-embie-red bg-embie-yellow"
              }`}
            >
              {curStep + 1 === STEPS.length ? "Envoyer" : "Continuer"}
            </button>
          </div>
        </form>
      )}
    </FormProvider>
  );
};

export default FormInvitation;
