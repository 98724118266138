import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from "react";

type Props = {
  children: React.ReactNode;
};

type Context = {
  theme: string;
  setTheme: Dispatch<SetStateAction<string>>;
};

const getInitialTheme = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    const storedPrefs = window.localStorage.getItem("theme");
    if (typeof storedPrefs === "string") {
      return storedPrefs;
    }
    const userMedia = window.matchMedia("(prefers-color-scheme: dark)");
    if (userMedia.matches) {
      return "dark";
    }
  }
  return "light";
};

const initialContext = {
  theme: "",
  setTheme: (): void => {
    throw new Error("setTheme function must be overridden");
  },
};

export const ThemeContext = createContext<Context>(initialContext);

export const ThemeProvider: FC<{ initialTheme?: string; children: ReactNode }> = ({ initialTheme, children }) => {
  const [theme, setTheme] = useState(getInitialTheme);
  const rawSetTheme = rawTheme => {
    const root = window.document.documentElement;
    const isDark = rawTheme === "dark";
    root.classList.remove(isDark ? "light" : "dark");
    root.classList.add(rawTheme);
    localStorage.setItem("theme", rawTheme);
  };
  if (initialTheme) {
    rawSetTheme(initialTheme);
  }
  useEffect(() => {
    rawSetTheme(theme);
  }, [theme]);
  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
};
