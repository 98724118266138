import React from "react";
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";

const CustomModal = () => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  return (
    <Modal isCentered closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent className="bg-embie-grey mx-4 sm:mx-0">
        <ModalHeader className="font-header">Covid-19 News</ModalHeader>
        <ModalBody className="font-content">
          Suite aux nouvelles mesures prises par le gouvernement pour contrer la propagation du COVID-19, nous devons déplacer la fête des 2
          ans d'Embie.
          <br />
          <br />
          Nous avons le plaisir de vous accueillir le <span className="font-bold">18 février 2022</span> pour célébrer.
          <br />
          <br />
          Plus d'informations vous seront communiquées incessamment sous peu.
          <br />
          <br />
          <span className="font-header font-semibold text-xl">Embie</span>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            onClick={() => {
              onClose();
            }}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-embie-grey bg-embie-blue hover:bg-embie-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-embie-grey focus:ring-embie-blue"
          >
            J'ai compris
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { CustomModal as Modal };
